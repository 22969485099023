<script setup lang="ts">
</script>

<template>
	<div class="flex flex-col h-full">
		<TheTopbar />
		<UContainer class="flex-1 py-8">
			<slot />
		</UContainer>
		<TheFooter />
	</div>
</template>

<style>
html {
    @apply bg-slate-100 h-full;
}

#__nuxt {
  @apply min-h-full grid;
}

body {
    @apply h-full;
}
</style>
